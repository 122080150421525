import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  BalancePage,
  BasketPage,
  EmailVerifiedPage,
  OrdersPage,
  ResetPasswordPage,
  SearchPage,
  ShipmentsPage,
  AboutPage,
  ContactPage,
  CatalogPage,
  BankPage,
  ManualsPage,
  LoginDialog,
  SignUpDialog,
  HomePage,
  GalleryPage,
  NewsListPage,
  NewsDetailedPage,
  NewEmailVerifiedPage,
} from './pages';

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/search" exact component={SearchPage} />
        <Route path="/basket" component={BasketPage} />
        <Route path="/gallery" component={GalleryPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/dispatch" component={ShipmentsPage} />
        <Route path="/balance" component={BalancePage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/email-verified" component={EmailVerifiedPage} />
        <Route path="/verify-new-email" component={NewEmailVerifiedPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/bank" component={BankPage} />
        <Route path="/offers" component={CatalogPage} />
        <Route path="/manuals" component={ManualsPage} />
        <Route path="/news" component={NewsListPage} />
        {/* <Route exact path="/news/:newsID" component={NewsDetailedPage} /> */}
        <Redirect to="/search" />
      </Switch>
      <Switch>
        <Route exact path="/login" component={LoginDialog} />
        <Route exact path="/*/sign-up" component={SignUpDialog} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
