import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import color from '@material-ui/core/colors/amber';
import classNames from 'classnames';
const PageTabs = (props) => {
  const { i18n, t } = useTranslation();
  const { classes, width, isLoggedIn } = props;
  const isScrollable = isWidthDown('sm', width);
  const [currentTab, setCurrentTab] = useState('');
  const productListTabs = [
    'search',
    'basket',
    'orders',
    'dispatch',
    'balance',
    'offers',
  ];

  useEffect(() => {
    setCurrentTab(window.location.pathname.split('/')[1]);
  }, [currentTab]);

  const handleChange = (value) => {
    if (value !== currentTab) {
      setCurrentTab(value);
    }
  };

  return (
    <div className={classes.container}>
      {!props.hideFirstRow && (
        <Tabs
          value={
            productListTabs.some((page) => page === currentTab)
              ? 'search'
              : currentTab || 'home'
          }
          onChange={handleChange}
          style={{ maxWidth: '100%', overflow: 'hidden' }}
          //variant={isScrollable ? 'scrollable' : 'standard'}
        >
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'home'}
            label={t('home')}
            to="/"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'about'}
            label={t('about')}
            to="/about"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'news'}
            label={t('news').split(' ')[0]}
            to="/news"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'search'}
            label={t('product_list')}
            to="/search"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'gallery'}
            label={t('gallery')}
            to="/gallery"
            component={Link}
          />
          <Tab
            className={classes.tab}
            classes={{
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            value={'contact'}
            label={t('contact')}
            to="/contact"
            component={Link}
          />
        </Tabs>
      )}

      {!props.hideSecondRow && (
        <Divider
          light
          style={{
            height: '5px',
            background: '#fff',
            width: '100%',
            minWidth: '460px',
            opacity: '1',
          }}
        />
      )}

      <motion.div
        initial="hidden"
        animate={
          productListTabs.some((page) => page === currentTab)
            ? 'visible'
            : 'hidden'
        }
      >
        {productListTabs.some((page) => page === currentTab) &&
          !props.hideSecondRow && (
            <>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                variant={isScrollable ? 'scrollable' : 'standard'}
              >
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'search'}
                  label={t('search')}
                  to="/search"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'basket'}
                  label={t('cart')}
                  to="/basket"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'orders'}
                  label={t('orders')}
                  to="/orders"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'dispatch'}
                  label={t('dispatch')}
                  to="/dispatch"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  disabled={!isLoggedIn}
                  value={'balance'}
                  label={t('balance')}
                  to="/balance"
                  component={Link}
                />
                <Tab
                  className={classNames(classes.tab, classes.tabDividers)}
                  classes={{
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  value={'offers'}
                  label={t('offers')}
                  to="/offers"
                  component={Link}
                />
              </Tabs>
            </>
          )}
      </motion.div>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;

  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: color.header,
      color: '#fff',
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
      justifyContent: 'center',
      //marginTop: '2px',
      [theme.breakpoints.down('sm')]: {},
    },
    button: {
      position: 'absolute',
      top: '9px',
      right: '24px',
      marginLeft: 'auto',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    tabWrapper: {
      '& > *': {
        fontSize: '1rem ',
      },
    },
    tab: {
      opacity: 1,
      transition: '0.3s',
      textTransform: 'none !important',
      // borderLeft: '1px solid white',
      // borderRight: '1px solid white',
      '&:last-child': {
        borderRight: 'none',
      },
      '&:first-child': {
        borderLeft: 'none',
      },
      '&:hover': {
        color: 'white',
        borderBottom: '1px solid white',
        transition: '0.3s',
      },
    },
    tabDividers: {
      '&:not(:last-child)::after': {
        content: '""',
        display: 'inline-block',
        backgroundColor: '#ccc',
        height: '100%',
        width: '2px',
        position: 'absolute',
        right: 0,
        top: '0',
      },
    },
    tabSelected: {
      backgroundColor: theme.palette.custom.pageTabs.tabSelected,
      borderRadius: '1px',
      color: '#fff',
    },
  };
};

export default withWidth()(withStyles(styles)(PageTabs));
