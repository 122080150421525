import {
  Page,
  HeroCarousel,
  NewsCarousel,
  GalleryCarousel,
  SmallCarousel,
  SearchBar,
  PageFooter,
} from '../components';
import { Grid } from '@material-ui/core';

const HomePage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Page hideFooter>
        <Grid container direction="conlumn" justify="center">
          <Grid item xs={10} md={12}>
            <SearchBar></SearchBar>
          </Grid>
          <Grid item xs={10} md={12}>
            <HeroCarousel></HeroCarousel>
          </Grid>

          <Grid item xs={10} md={12}>
            <NewsCarousel />
          </Grid>
          <Grid item xs={10} md={12}>
            <GalleryCarousel />
          </Grid>
        </Grid>
      </Page>
      <Grid container justify="center" style={{ background: '#fff' }}>
        <Grid item xs={10} md={10} lg={10}>
          <SmallCarousel path={'aftermarket'} />
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ background: '#e4edfa' }}>
        <Grid item xs={10} md={10} lg={10}>
          <SmallCarousel path={'oem'} />
        </Grid>
      </Grid>
      <PageFooter />
    </div>
  );
};

export default HomePage;
