import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import * as userApi from '../api/user-api';
import classNames from 'classnames';
import * as dictionaryApi from '../api/dictionary-api';
import { getCookie } from '../utils/cookies';
import { setCookie } from '../utils/cookies';
import {
  PageHeader,
  PageTabs,
  PageContent,
  PageFooter,
  LoginDialog,
  SignUpDialog,
  UserDataDialog,
  VerifyEmailAlertDialog,
} from '../components';

const Page = ({ classes, children, render, hideFooter }) => {
  const [isLoginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isSignUpDialogOpen, setSignUpDialogOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('access_token')
  );
  const [loginError, setLoginError] = useState('');
  const [signUpError, setSignUpError] = useState('');
  const [passResetError, setPassResetError] = useState('');
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isAppOpen, setAppOpen] = useState(false);
  const [verifyDialogOpen, setVerifyDialogOpen] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [isUserDataDialogOpen, setUserDataDialogOpen] = useState(false);
  const [isUserDataSubmitting, setUserDataSubmitting] = useState(false);
  const [submitUserDataError, setSubmitUserDataError] = useState('');

  const [countryList, setCountryList] = useState([]);

  const productListTabs = [
    'search',
    'basket',
    'orders',
    'dispatch',
    'balance',
    'offers',
  ];

  async function getCountryList() {
    setCountryList([]);
    const result = await dictionaryApi.countries();

    setCountryList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }
  const [currencyList, setCurrencyList] = useState([]);

  async function getCurrencyList() {
    setCurrencyList([]);
    const result = await dictionaryApi.currencies();

    setCurrencyList(
      result.map((row) => ({
        title: row['name'],
        value: row['code'],
      }))
    );
  }

  const [userData, setUserData] = useState({
    username: '',
    countryCode: '',
    zipCode: '',
    city: '',
    state: '',
    street: '',
    building: '',
    flat: '',
    phone: '',
    contactPerson: '',
    contactPhone: '',
    email: '',
    trn: '',
    currencyCode: '',
  });

  useEffect(() => {
    const checkLogin = setInterval(
      () =>
        isLoggedIn !== !!localStorage.getItem('access_token') &&
        setIsLoggedIn(!isLoggedIn),
      500
    );

    try {
      getUserData();
      getCountryList();
      getCurrencyList();
    } catch (error) {
      // silence
    }

    return () => clearInterval(checkLogin);
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      productListTabs.some(
        (page) => page === window.location.hash.split('/')[1]
      )
    )
      setAppOpen(true);
  }, []);

  async function getUserData() {
    setUserData({
      username: '',
      countryCode: '',
      zipCode: '',
      city: '',
      state: '',
      street: '',
      building: '',
      flat: '',
      phone: '',
      contactPerson: '',
      contactPhone: '',
      email: '',
      trn: '',
      currencyCode: '',
      customerCode: '',
    });

    const result = await userApi.getData();

    if (result !== null && result.length !== 0) {
      //setCookie('currency', result[0].currencyCode);

      setUserData({
        username: result[0].username,
        countryCode: result[0].countryCode,
        zipCode: result[0].zipCode,
        city: result[0].city,
        state: result[0].state,
        street: result[0].street,
        building: result[0].building,
        flat: result[0].flat,
        phone: result[0].phone,
        contactPerson: result[0].contactPerson,
        contactPhone: result[0].contactPhone,
        email: result[0].email,
        trn: result[0].trn,
        currencyCode: result[0].currencyCode,
        customerCode: result[0].customerCode,
      });
    } else {
      await userApi.logout();
    }
  }

  async function handleLogin(email, password) {
    try {
      setSubmitting(true);
      await userApi.login(email, password);
      setLoginDialogOpen(false);
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSignUp(user) {
    try {
      setSubmitting(true);
      setSignUpError(null);
      const { currencyCode } = user;
      delete user.currencyCode;

      const res = await userApi.signUp(user);
      setNewEmail(user.email);
      setSignUpDialogOpen(false);
      setVerifyDialogOpen(true);

      try {
        await userApi.login(user.email, user.password);

        if (currencyCode) {
          const ud = userApi.getData();
          if (ud.currencyCode !== currencyCode) {
            ud.currencyCode = currencyCode;
            await userApi.setData(ud);
          }
        }
      } catch (error) {
        setLoginError(error.message);
      }

      setSubmitting(false);

      //window.location.reload();
    } catch (error) {
      console.log(error.message);
      setSignUpError(error.message);
      setSubmitting(false);
    }
  }

  async function handleSubmitUserData(data) {
    try {
      setUserDataSubmitting(true);
      data.lang = localStorage.getItem('selectedLanguage');
      const status = await userApi.setData(data);
      setUserDataDialogOpen(false);
      setUserDataSubmitting(false);
      if (status === 206) {
        setNewEmail(data.email);
        setVerifyDialogOpen(true);
      }
      getUserData();
      // window.location.reload();
    } catch (error) {
      setSubmitUserDataError(error.message);
      setUserDataSubmitting(false);
    }
  }

  function handleUserDataDialogOpen() {
    setUserDataDialogOpen(true);
  }

  function handleUserDataDialogClose() {
    setUserDataDialogOpen(false);
  }

  function handleLoginDialogClose() {
    setLoginDialogOpen(false);
  }

  function handleLoginDialogOpen() {
    setLoginDialogOpen(true);
  }

  function handleSignUpDialogClose() {
    setSignUpDialogOpen(false);
  }

  function handleSignUpDialogOpen() {
    setSignUpDialogOpen(true);
  }

  async function handleLogout() {
    try {
      await userApi.logout();
      window.location.reload();
    } catch (error) {
      // empty
    }
  }

  async function handlePasswordReset(email) {
    try {
      await userApi.reset(email);
      setPasswordReset(true);
    } catch (err) {
      setPassResetError(err.message);
    }
  }

  return (
    <div className={classes.pageWrapper}>
      <PageHeader
        companyName={window.COMPANY_NAME}
        companyAddress={window.COMPANY_ADDRESS}
        customerCode={userData.customerCode}
        isLoggedIn={isLoggedIn}
        onLogin={handleLoginDialogOpen}
        onSignUp={handleSignUpDialogOpen}
        onLogout={handleLogout}
        onUserData={handleUserDataDialogOpen}
      />
      <div className={isAppOpen ? classes.appPage : classes.page}>
        <PageContent>{render ? render({ isLoggedIn }) : children}</PageContent>
        {isUserDataDialogOpen && (
          <UserDataDialog
            error={submitUserDataError}
            isSubmitting={isUserDataSubmitting}
            onClose={handleUserDataDialogClose}
            onSubmit={handleSubmitUserData}
            countryList={countryList}
            currencyList={currencyList}
            userProfileData={userData}
          />
        )}
        {isLoginDialogOpen && (
          <LoginDialog
            error={loginError || passResetError}
            isSubmitting={isSubmitting}
            isPasswordReset={isPasswordReset}
            onClose={handleLoginDialogClose}
            onLogin={handleLogin}
            onPasswordReset={handlePasswordReset}
          />
        )}
        {isSignUpDialogOpen && (
          <SignUpDialog
            error={signUpError}
            isSubmitting={isSubmitting}
            onClose={handleSignUpDialogClose}
            onSubmit={handleSignUp}
            setError={setSignUpError}
            currencyList={currencyList}
          />
        )}
        <VerifyEmailAlertDialog
          open={verifyDialogOpen}
          onCloseClick={() => setVerifyDialogOpen(false)}
          email={newEmail}
        />
      </div>

      {!hideFooter && <PageFooter />}
    </div>
  );
};

const styles = (theme) => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '100vh',
  },

  page: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    //    height: '100%',
    width: '100%',
    flex: '1 0 auto',
  },
  appPage: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    flex: '1 0 auto',
    //    height: '100%',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '1280px',
    },
  },
});

export default withStyles(styles)(Page);
