import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomNextArrow, CustomPrevArrow } from './HeroCarousel';

const StyledCarousel = ({ width, children, title, classes }) => {
  const slidesToShow = isWidthDown('sm', width)
    ? 1
    : isWidthDown('md', width)
    ? 2
    : 3;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow classes={classes.next} />,
    prevArrow: <CustomPrevArrow classes={classes.prev} />,
  };

  return (
    <div style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h4" align="left">
        {title}
      </Typography>
      <Divider style={{ marginBottom: '20px', background: '#000000aa' }} />
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

const styles = (theme) => {
  return {
    slider: {
      overflow: 'hidden',
      height: '470px',
      [theme.breakpoints.down('md')]: {
        height: '300px',
      },
    },

    prev: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      borderRadius: '25px',
      '&:hover': {
        transition: 'all 0.5s !important',
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },

    next: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      border: 'none',
      borderRadius: '25px',
      '&:hover': {
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },
  };
};

export default withWidth()(withStyles(styles)(StyledCarousel));
