import React from 'react';
import { Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

const PageFooter = ({ classes }) => {
  const { t } = useTranslation();
  function handlePolicyClick() {
    window.open('policy.pdf');
    return false;
  }

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className={classes.footer}>
      <Grid container fluid spacing={3} className={classes.root}>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.title}>
            {t('contact_us')}
          </Typography>
          <Typography variant="body1">Nippon Motors FZCO,</Typography>
          <Typography variant="body1">Land S31220,</Typography>
          <Typography variant="body1">Jebel Ali Free Zone, Dubai,</Typography>
          <Typography variant="body1">United Arab Emirates</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.title}>
            {t('my_account')}
          </Typography>
          <List>
            <ListItem>
              <Link to="/search" className={classes.link}>
                <ListItemText primary={t('search_product')} />
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/basket" className={classes.link}>
                <ListItemText primary={t('shopping_cart')} />
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/balance" className={classes.link}>
                <ListItemText primary={t('my_account')} />
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/offers" className={classes.link}>
                <ListItemText primary={t('offers')} />
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" className={classes.title}>
            {t('resources')}
          </Typography>
          <List>
            <ListItem>
              <Link to="/contact" className={classes.link}>
                <ListItemText primary={t('contact_us')} />
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/about" className={classes.link}>
                <ListItemText primary={t('about')} />
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/gallery" className={classes.link}>
                <ListItemText primary={t('gallery')} />
              </Link>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            classes={{ root: classes.dividerRoot }}
          />
          <Typography variant="h6" className={classes.title}>
            © {getCurrentYear()} Nippon Motors FZCO.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
/*
<div className={classes.paylogo}>
  <img src="/visa-pay-logo.svg" />
  <img src="/master-card-logo.svg" />
</div>
*/
/*
            <ListItem>
              <Link to="/manuals" className={classes.link}>
                <ListItemText primary="FAQ" />
              </Link>
            </ListItem>
*/
const styles = (theme) => {
  const colors = theme.palette.custom.pageHeader;
  return {
    footer: {
      backgroundImage: 'linear-gradient(45deg,#f0f1f9, #b3c1e4)',
      minHeight: '36px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        minHeight: '24px',
      },
    },
    toolbar: {
      flex: '1 0 0px',
      justifyContent: 'space-between',
    },
    name: {
      color: theme.palette.custom.logo,
      marginLeft: theme.spacing.unit,
    },
    paylogo: {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        height: '40px',
      },
      [theme.breakpoints.down('xs')]: {
        '& > img': {
          height: '20px',
        },
      },
    },
    address: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
    },
    link: {
      color: theme.palette.custom.logo,
      fontWeight: 'normal',
    },
    root: {
      padding: '30px 10px',
      color: '#fff',
      maxWidth: '1264px',
      margin: '0 auto',
    },
    title: {},
    dividerRoot: {
      backgroundColor: '#fff',
      height: '5px',
      margin: '10px 0',
    },
  };
};

export default withStyles(styles)(PageFooter);
