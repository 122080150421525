import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qualityImage from '../assets/imgs/gallery/gallery2.png';
import { Page, SearchBar } from '../components';
import {
  Typography,
  Grid,
  Paper,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const AboutPage = ({ classes }) => {
  const { t } = useTranslation();

  const listItems = [
    t('item1'),
    t('item2'),
    t('item3'),
    t('item4'),
    t('item5'),
    t('item6'),
    t('item7'),
    t('item8'),
    t('item9'),
    t('item10'),
    t('item11'),
    t('item12'),
    t('item13'),
    t('item14'),
  ];

  const [content, setContent] = useState('');
  const column1 = listItems.slice(0, listItems.length / 2);
  const column2 = listItems.slice(listItems.length / 2);
  useEffect(() => {
    const fetchContent = async () => {
      const result = await axios.get('/about.html');
      setContent(result.data);
    };
    fetchContent();
  }, []);

  return (
    <Page>
      <Grid container direction="column">
        <Grid item xs={10} md={12}>
          <SearchBar></SearchBar>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.mainHeading}>
            {t('companyProfileHeading')}
          </Typography>
          <Typography paragraph className={classes.text}>
            <b> Nippon Motors FZCO </b> {t('para1')}
          </Typography>
          <Typography paragraph className={classes.text}>
            <b> Nippon Motors FZCO </b> {t('para2')}
          </Typography>
          <Typography paragraph className={classes.text}>
            <b> Nippon Motors </b> {t('para3')}
            <b>
              {' '}
              Toyota, Mitsubishi, Nissan, Mazda, Isuzu, Honda, Subaru, Daihatsu,
              Hyundai, Kia, Daewoo, Audi, Land Rover, Mercedes,...
            </b>
          </Typography>
          <Typography paragraph className={classes.text}>
            <b> Nippon Group’s Company </b> {t('para4')}
          </Typography>
          <Typography paragraph className={classes.text}>
            <b>{t('para6')}</b> {t('para5')}
          </Typography>
        </Grid>
        <Grid container direction="row" style={{ margin: '60px 0' }}>
          <Grid item md={6} sm={12}>
            <Typography variant="h6" className={classes.subHeading}>
              {t('whyChooseUsHeading')}
            </Typography>
            <Typography variant="h4" className={classes.mainHeading}>
              {t('qualityPolicyHeading')}
            </Typography>
            <Typography paragraph className={classes.text}>
              {t('qualityPolicyText')}
            </Typography>
          </Grid>

          <Grid item md={6} sm={12}>
            <img src={qualityImage} className={classes.image} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.mainHeading}>
            {t('ourMissionHeading')}
          </Typography>
          <Typography paragraph className={classes.text}>
            {t('ourMissionText')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Typography variant="h4" className={classes.mainHeading}>
            {t('ourSpecialtyHeading')}
          </Typography>
          <Typography paragraph className={classes.text}>
            {t('ourSpecialtyText1')}
          </Typography>
          <Typography paragraph className={classes.text}>
            {t('ourSpecialtyText2')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '30px' }}>
        <Grid item sm={6} xs={12}>
          <List>
            {column1.map((position, index) => (
              <ListItem key={index} className={classes.item}>
                <ListItemIcon>
                  <RadioButtonUncheckedIcon className={classes.itemIcon} />{' '}
                </ListItemIcon>
                <Typography variant="body1">{position}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item sm={6} xs={12}>
          <List>
            {column2.map((position, index) => (
              <ListItem key={index} className={classes.item}>
                <ListItemIcon>
                  <RadioButtonUncheckedIcon className={classes.itemIcon} />{' '}
                  {/* Replace with the icon you want */}
                </ListItemIcon>
                <Typography variant="body1">{position}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  return {
    mainHeading: {
      fontWeight: '600',
      display: 'block',
      margin: '10px 0',
    },

    subHeading: {
      display: 'block',
      color: '#194681',
      margin: '10px 0',
    },
    item: {
      paddingTop: '0',
    },
    itemIcon: {
      fontSize: '10px',
      color: '#000000BB',
    },
    image: {
      width: 'clamp(300px,100%,450px)',
      padding: '10px',
      margin: '10px auto',
    },
    text: {
      fontSize: '1.1rem',
    },
  };
};

export default withStyles(styles)(AboutPage);
