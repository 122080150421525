import axios from 'axios';

const STRAPI_URL = process.env.REACT_APP_STRAPI_API_URL;

const fetchNews = async (lang) => {
  try {
    const response = await axios.get(
      `${STRAPI_URL}/news-items?locale=${lang}&populate=image`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error while fetching news', error);
  }
};

export const fetchSingleNews = async (id) => {
  try {
    const response = await axios.get(
      `${STRAPI_URL}/news-items/${id}?&populate=localizations,image`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error while fetching news', error);
  }
};

export default fetchNews;
