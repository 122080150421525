import React, { useEffect, useState, memo } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { Page } from '../components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getData, verifyNewEmail } from '../api/user-api';

function NewEmailVerifiedPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    if (!email) {
      history.push('/search');
      return;
    }
    verify(email);
  }, []);

  const getUserData = async (email) => {
    const result = await getData();

    if (result[0].email === email) {
      history.push('/search');
      return;
    }

    if (result !== null && result.length !== 0) {
      return {
        username: result[0].username,
        countryCode: result[0].countryCode,
        zipCode: result[0].zipCode,
        city: result[0].city,
        state: result[0].state,
        street: result[0].street,
        building: result[0].building,
        flat: result[0].flat,
        phone: result[0].phone,
        contactPerson: result[0].contactPerson,
        contactPhone: result[0].contactPhone,
        email: email,
        trn: result[0].trn,
        currencyCode: result[0].currencyCode,
        customerCode: result[0].customerCode,
      };
    }
  };

  const verify = async (email) => {
    const status = await verifyNewEmail({ token: email });
    if (status === 200) {
      setIsSuccess(true);
    } else {
      setError(true);
    }
    setIsVerifying(false);
    return;
  };

  return (
    <Page>
      {isVerifying ? (
        <>
          <CircularProgress></CircularProgress>
          <Typography variant="h6">{t('verify_email_processing')}</Typography>
        </>
      ) : isSuccess ? (
        <Typography variant="h6">{t('email_verified')}</Typography>
      ) : (
        <Typography variant="h6">{t('error_something')}</Typography>
      )}
    </Page>
  );
}

export default memo(NewEmailVerifiedPage);
