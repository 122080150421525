import axios from 'axios';

export const SMARTTRADE_API_URL = process.env.REACT_APP_SMARTTRADE_API_URL;

// export default axios.create({
//   baseURL: SMARTTRADE_API_URL,
//   withCredentials: true,
// });

/*
const http = axios.create({
  baseURL: SMARTTRADE_API_URL,
  withCredentials: true,
  headers: {
    Authorization: localStorage.getItem('access_token'),
  },
});

export const setAuthToken = (token) => {
  http.defaults.headers.common['Authorization'] = `${token}`;
};
 */

const http = axios.create({
  baseURL: SMARTTRADE_API_URL,
  withCredentials: true,
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
