import React from 'react';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

export const CustomNextArrow = ({ classes, className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIcon fontSize="small" classes={{ root: classes }} />
    </div>
  );
};

export const CustomPrevArrow = ({ classes, className, style, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIcon fontSize="small" classes={{ root: classes }} />
    </div>
  );
};

const SmallCarousel = ({ classes, path }) => {
  const { t } = useTranslation();
  let imageContext;
  if (path === 'aftermarket') {
    imageContext = require.context(
      '../assets/imgs/aftermarket',
      false,
      /\.(png|jpe?g|svg)$/
    );
  } else if (path === 'oem') {
    imageContext = require.context(
      '../assets/imgs/oem',
      false,
      /\.(png|jpe?g|svg)$/
    );
  }
  const images = imageContext.keys().map(imageContext);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomNextArrow classes={classes.next} />,
    prevArrow: <CustomPrevArrow classes={classes.prev} />,
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" align="left">
        {path === 'oem' ? t('oem_brands') : t('aftermarket_brands')}
      </Typography>
      <Divider style={{ marginBottom: '20px', background: '#000000aa' }} />
      <Slider {...settings}>
        {images.map((image, index) => (
          <div className={classes.slider} key={index}>
            <img src={image} alt={`img-${index}`} className={classes.image} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const styles = (theme) => {
  return {
    container: {
      margin: '30px auto',
      maxWidth: '1280px',
    },
    slider: {
      overflow: 'hidden',
      height: 'auto',
      userSelect: 'none',
      [theme.breakpoints.down('md')]: {
        minHeight: 'auto',
      },
    },
    image: {
      width: '90%',
      maxWidth: '150px',
      margin: '10% auto',
    },
    prev: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      borderRadius: '25px',
      position: 'absolute',
      right: '0px',
      '&:hover': {
        transition: 'all 0.5s !important',
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },

    next: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      border: 'none',
      position: 'absolute',
      right: '0px',
      borderRadius: '25px',
      '&:hover': {
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },
  };
};

export default withStyles(styles)(SmallCarousel);
