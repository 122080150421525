import emailjs from '@emailjs/browser';

export const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID;
export const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
export const public_key = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

const sendForm = async (formRef) => {
  const result = await emailjs.sendForm(
    service_id,
    template_id,
    formRef.current,
    public_key
    //TODO: INSERT PROPER VALUES
  );
  return result;
};

export default sendForm;
