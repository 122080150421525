import React, { useState } from 'react';
import { SignUpDialog as BaseSignUpDialog } from '../components';
import * as userApi from '../api/user-api';

const SignUpDialog = ({ history }) => {
  const [signUpError, setSignUpError] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  async function handleSignUp(user) {
    try {
      setSubmitting(true);
      const res = await userApi.signUp(user);
      handleSignUpDialogClose();
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setSignUpError(error.message);
      setSubmitting(false);
    }
  }

  function handleSignUpDialogClose() {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }

  return (
    <BaseSignUpDialog
      error={signUpError}
      isSubmitting={isSubmitting}
      onClose={handleSignUpDialogClose}
      onSubmit={handleSignUp}
    />
  );
};

export default SignUpDialog;
