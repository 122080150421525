import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import fetchNews, { fetchSingleNews } from '../api/news-api';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Divider,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { Page, SearchBar } from '../components';
import { Grid } from '@material-ui/core';

const styles = (theme) => {
  return {
    hero: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: 'white',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: 'auto',
    },
    heroContent: {
      position: 'relative',
      zIndex: 2,
      width: '80%',
      backgroundColor: '#194681',
      borderRadius: '10px',
      boxShadow: '8px 8px 24px -16px rgba(0, 0, 0, 1)',
    },
    heroImg: {
      height: '70%',
      width: '100%',
    },
    backgroundOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    title: {
      color: '#000',
      marginTop: '30px',
    },

    text: {
      '& > p,ul,li': {
        fontSize: '18px',
      },
    },
    divider: {
      borderTop: '2px solid #194681',
    },
  };
};

const NewsDetailedPage = ({ classes, newsId }) => {
  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = useState([]);
  const [post, setPost] = useState(null);

  const history = useHistory();

  const getNewsList = async (id) => {
    let res = await fetchSingleNews(id);

    if (res.data.attributes.locale !== i18n.language) {
      const required = res.data.attributes.localizations?.data.find(
        (post) => post.attributes.locale === i18n.language
      );

      if (!required.id) history.push('/news');

      res = await fetchSingleNews(required.id);
    }
    setNewsList(res.data.attributes.localizations?.data);
    setPost(res.data);
  };

  useEffect(() => {
    if (!post) getNewsList(newsId).then();
    else {
      const post = newsList.find(
        (post) => post.attributes.locale === i18n.language
      );
      if (!post.id) history.push('/news');
      getNewsList(post.id).then();
    }
  }, [i18n.language]);

  const HeroSection = () => (
    <Card className={classes.hero}>
      <img
        src={
          post?.attributes.image?.data?.attributes.url
            ? `${
                process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                post?.attributes.image?.data?.attributes.url
              }`
            : `${process.env.PUBLIC_URL}/assets/img/dummy.jpg`
        }
        className={classes.heroImg}
      ></img>
    </Card>
  );

  return (
    <Page>
      <Grid container direction="conlumn" justify="center">
        <Grid item xs={10} md={12}>
          <SearchBar></SearchBar>
        </Grid>

        <Grid item xs={10} md={12}>
          <HeroSection />

          <Typography variant="h3" className={classes.title}>
            {post?.attributes.title}
          </Typography>

          <Typography variant="h6">
            {new Date(post?.attributes.date).toLocaleDateString(
              i18n.language === 'ru' ? 'ru-RU' : 'en-US',
              {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }
            )}
          </Typography>

          <Divider
            variant="fullWidth"
            classes={{
              root: classes.divider,
            }}
          />

          <Typography
            variant="subtitle1"
            component="pre"
            style={{
              marginTop: '30px',
              whiteSpace: 'pre-wrap',
            }}
          >
            <ReactMarkdown className={classes.text}>
              {post?.attributes.text}
            </ReactMarkdown>
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

export default withStyles(styles)(NewsDetailedPage);
