import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';
import fetchNews from '../api/news-api';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { Page, SearchBar } from '../components';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NewsDetailedPage from './NewsDetailedPage';

const NewsListPage = ({ classes }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsId = searchParams.get('id');

  const getNewsList = async () => {
    const res = await fetchNews(i18n.language);
    setNewsList(res.data?.sort((a, b) => b.id - a.id).slice(0, 5));
  };

  useEffect(() => {
    getNewsList();
  }, [i18n.language]);

  const handleNavigeToDetailedPost = (id) => {
    history.push({
      pathname: '/news',
      search: `?id=${id}`,
    });
  };

  if (newsId) {
    return <NewsDetailedPage newsId={newsId} />;
  }

  return (
    <Page>
      <Grid container direction="conlumn" justify="center">
        <Grid item xs={10} md={12}>
          <SearchBar></SearchBar>
        </Grid>

        {newsList.map((item, index) => (
          <Grid
            key={index}
            xs={10}
            sm={6}
            md={4}
            lg={4}
            style={{ padding: '10px', borderRadius: '10px' }}
            onClick={() => handleNavigeToDetailedPost(item.id)}
          >
            <Card
              classes={{
                root: classes.card,
              }}
              style={{}}
            >
              <CardMedia
                style={{
                  height: '250px',
                  padding: '10px',
                  borderRadius: '10px',
                }}
                image={
                  process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                  item.attributes.image?.data?.attributes.formats.small.url
                }
                title={item.attributes.title}
              />
              <CardContent>
                <Typography variant="h6" color="textSecondary" component="h6">
                  {new Date(item.attributes.date).toLocaleDateString(
                    i18n.language === 'ru' ? 'ru-RU' : 'en-US',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    }
                  )}
                </Typography>
                <Typography variant="h6" color="#194681">
                  {item.attributes?.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="pre"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  <ReactMarkdown>
                    {item.attributes.summary?.slice(0, 200)}
                  </ReactMarkdown>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

const styles = (theme) => {
  return {
    card: {
      margin: '5px',
      padding: '10px',
      height: '460px',
      borderRadius: '10px',
      transition: '0.25s ease-in-out',
      '&:hover': {
        backgroundColor: 'rgba(0, 0,0, 0.11)',
        cursor: 'pointer',
        border: '1px solid #194681',
      },
    },
  };
};

export default withStyles(styles)(NewsListPage);
