import StyledCarousel from './StyledCarousel';
import gallery1 from '../assets/imgs/gallery/gallery1.png';
import gallery2 from '../assets/imgs/gallery/gallery2.png';
import gallery3 from '../assets/imgs/gallery/gallery3.png';
import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

const data = [
  gallery1,
  gallery2,
  gallery3,
  // ... additional image URLs
];

const CarouselWithFullscreenImages = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <StyledCarousel title={t('gallery')}>
        {data.map((image, index) => (
          <div key={index} style={{ padding: '10px' }}>
            <img
              src={image}
              alt={`img-${index}`}
              style={{
                width: '100%',
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '15px',
              }}
              onClick={() => handleClickOpen(image)}
            />
          </div>
        ))}
      </StyledCarousel>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Fullscreen" style={{ width: '100%' }} />
      </Dialog>
    </div>
  );
};

export default CarouselWithFullscreenImages;
