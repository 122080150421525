import React, { useState } from 'react';
import { Grid, withStyles, Dialog } from '@material-ui/core';
import { Page, SearchBar } from '../components';

const GalleryPage = ({ classes }) => {
  let imageContext = require.context(
    '../assets/imgs/gallery',
    false,
    /\.(png|jpe?g|svg)$/
  );
  const images = imageContext.keys().map(imageContext);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Page>
      <Grid item xs={10} md={12}>
        <SearchBar></SearchBar>
      </Grid>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            className={classes.imgContainer}
          >
            <img
              src={image}
              alt={`img-${index}`}
              className={classes.img}
              style={{
                width: '100%',
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '15px',
              }}
              onClick={() => handleClickOpen(image)}
            />
          </Grid>
        ))}
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <img src={selectedImage} alt="Fullscreen" style={{ width: '100%' }} />
        </Dialog>
      </Grid>
    </Page>
  );
};

const styles = (theme) => ({
  imgContainer: {
    padding: '10px',
  },
  img: {
    width: '100%',
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '15px',
    '&:hover': {
      filter: 'brightness(80%)',
    },
  },
});

export default withStyles(styles)(GalleryPage);
