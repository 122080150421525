import React, { useState } from 'react';
import { LoginDialog as BaseLoginDialog } from '../components';
import * as userApi from '../api/user-api';
import { useTranslation } from 'react-i18next';

const LoginDialog = ({ history }) => {
  const { t } = useTranslation();
  const [loginError, setLoginError] = useState('');
  const [passResetError, setPassResetError] = useState('');
  const [isPasswordReset, setPasswordReset] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  async function handleLogin(email, password) {
    try {
      setSubmitting(true);
      await userApi.login(email, password);
      handleLoginDialogClose();
      setSubmitting(false);
      window.location.reload();
    } catch (error) {
      setLoginError(error.message);
      setSubmitting(false);
    }
  }

  async function handlePasswordReset(email) {
    try {
      await userApi.reset(email);
      setPasswordReset(true);
    } catch (err) {
      setPassResetError(err.message);
    }
  }

  function handleLoginDialogClose() {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }

  return (
    <BaseLoginDialog
      error={loginError || passResetError}
      isSubmitting={isSubmitting}
      isPasswordReset={isPasswordReset}
      onClose={handleLoginDialogClose}
      onLogin={handleLogin}
      onPasswordReset={handlePasswordReset}
    />
  );
};

export default LoginDialog;
