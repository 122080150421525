import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import img from '../assets/imgs/news_compressed.jpg';
import {
  Typography,
  Divider,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomNextArrow, CustomPrevArrow } from './HeroCarousel';
import { useTranslation } from 'react-i18next';
import fetchNews from '../api/news-api';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NewsCarousel = ({ width, classes }) => {
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [newsList, setNewsList] = React.useState([]);
  const slidesToShow = isWidthDown('sm', width)
    ? 1
    : isWidthDown('md', width)
    ? 2
    : 3;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow classes={classes.next} />,
    prevArrow: <CustomPrevArrow classes={classes.prev} />,
  };

  const getNewsList = async () => {
    const res = await fetchNews(i18n.language);

    if (res.data && Array.isArray(res.data))
      setNewsList(res.data?.sort((a, b) => b.id - a.id).slice(0, 5));
    else {
      setNewsList([]);
    }
  };

  React.useEffect(() => {
    getNewsList();
  }, [i18n.language]);

  const handleNavigeToDetailedPost = (id) => {
    history.push(`/news?id=${id}`);
  };

  return (
    <div style={{ padding: '20px', marginTop: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" align="left">
          {t('news')}
        </Typography>
        <a href="/news" style={{ borderBottom: '2px solid #194681' }}>
          {t('view_all')}
        </a>
      </div>

      <Divider style={{ marginBottom: '20px', background: '#000000aa' }} />
      <Slider {...settings}>
        {newsList.map((item, index) => (
          <div key={index} style={{ padding: '10px', borderRadius: '10px' }}>
            <Card
              className={classes.card}
              onClick={() => handleNavigeToDetailedPost(item.id)}
            >
              <CardMedia
                style={{
                  height: '250px',
                  padding: '10px',
                  borderRadius: '10px',
                }}
                image={
                  process.env.REACT_APP_STRAPI_API_URL.slice(0, -4) +
                  item.attributes.image?.data?.attributes.formats.small.url
                }
                title={item.attributes.title}
              />
              <CardContent>
                <Typography variant="h6" color="textSecondary" component="h6">
                  {new Date(item.attributes.date).toLocaleDateString(
                    i18n.language === 'ru' ? 'ru-RU' : 'en-US',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    }
                  )}
                </Typography>
                <Typography variant="h6" color="#194681">
                  {item.attributes.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="pre"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  <ReactMarkdown>
                    {item.attributes.summary?.slice(0, 200)}
                  </ReactMarkdown>
                </Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const styles = (theme) => {
  return {
    slider: {
      overflow: 'hidden',
      height: '470px',
      [theme.breakpoints.down('md')]: {
        height: '200px',
      },
    },

    card: {
      margin: '5px',
      padding: '10px',
      height: '437px',
      borderRadius: '0px',
      transition: '0.25s ease-in-out',
      '&:hover': {
        backgroundColor: 'rgba(0, 0,0, 0.11)',
        cursor: 'pointer',
        border: '1px solid #194681',
      },
    },

    prev: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      borderRadius: '25px',
      '&:hover': {
        transition: 'all 0.5s !important',
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },

    next: {
      color: '#194681',
      fontSize: '1.5rem',
      transition: 'all 0.5s !important',
      border: 'none',
      borderRadius: '25px',
      '&:hover': {
        border: '1px solid #194681',
        color: '#fff',
        backgroundColor: '#194681',
      },
    },
  };
};

export default withWidth()(withStyles(styles)(NewsCarousel));
