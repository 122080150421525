export const SortByColumn = (data, sortConfig) => {
  let sortableItems = [...data];
  if (sortConfig !== null) {
    sortableItems.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1; //is null, place it at the bottom

      const bothAreNumbers = !isNaN(Number(aValue)) && !isNaN(Number(bValue));

      if (bothAreNumbers) {
        aValue = Number(aValue);
        bValue = Number(bValue);
      } else {
        aValue = String(aValue);
        bValue = String(bValue);
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0; // values are equal
    });
  }
  return sortableItems;
};
