import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

// Search bar component
const SearchBar = ({ classes }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push({
      pathname: '/search',
      search: `?part=${search}`,
    });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.formContainer}>
      <input
        type="text"
        placeholder={t('search_input_placeholder')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={classes.input}
      />
      <button type="submit" className={classes.button}>
        <SearchIcon fontSize="small" />
        <span style={{ marginLeft: '4px', fontSize: '1rem' }}>
          {t('search')}
        </span>
      </button>
    </form>
  );
};
const styles = (theme) => {
  return {
    formContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: '10px',
    },
    input: {
      width: '100%',
      border: 'none',
      borderBottom: '1px solid black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: '-7px 11px 25px -7px rgba(0, 0, 0, 0.43)',
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#000',
      border: 'none',
      padding: '5px 12px',
      borderRadius: '0px 8px 8px 0px',
      color: '#fff',

      '&:hover': {
        backgroundColor: '#194681',
      },
    },
  };
};
export default withStyles(styles)(SearchBar);
