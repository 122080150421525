import React, { useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Rating from 'material-ui-rating';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import StarRateIcon from '@material-ui/icons/StarRate';
import QuantityField from './QuantityField';
import { SortByColumn } from '../utils/sorting';

const RowCheckbox = ({ id, checked, onChange, className }) => {
  function handleOnChange() {
    onChange && onChange(id);
  }

  return (
    <Checkbox
      className={className}
      disableRipple
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

const SearchTable = ({
  classes,
  data,
  showReference,
  onDataChange,
  isLoggedIn,
  onSelect,
  onSelectAll,
}) => {
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const sortedItems = React.useMemo(
    () => SortByColumn(data, sortConfig),
    [data, sortConfig]
  );

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      setSortConfig({ key: null, direction: 'ascending' });
      return;
    }
    setSortConfig({ key, direction });
  };

  function handleQuantityChange(id, quantity) {
    const newData = data.map((value) =>
      value.id !== id
        ? value
        : {
            ...value,
            quantity,
          }
    );
    onDataChange && onDataChange(newData);
  }

  function handleReferenceChange(id, yourReference) {
    const newData = data.map((value) =>
      value.id !== id
        ? value
        : {
            ...value,
            yourReference,
          }
    );
    onDataChange && onDataChange(newData);
  }

  function handleSelectAll(event) {
    onSelectAll && onSelectAll(event.target.checked);
  }

  const confirmedData = useMemo(
    () => data.filter((value) => value.confirmed),
    [data]
  );

  const isAllConfirmed = confirmedData.length === data.length;

  const currency = data.length ? data[0].currency : '';

  return (
    <div className={`${classes.container} search-table`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                // indeterminate={numSelected > 0 && numSelected < rowCount}
                className={classes.checkbox}
                disableRipple
                checked={isAllConfirmed}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell className={classes.headerCell}>
              <div onClick={() => requestSort('partNumber')}>
                {t('part_number')}
                {sortConfig.key === 'partNumber'
                  ? sortConfig.direction === 'ascending'
                    ? ' ↑'
                    : ' ↓'
                  : ''}
              </div>
              <div onClick={() => requestSort('inpPartNumber')}>
                {t('substituted')}
                {sortConfig.key === 'inpPartNumber'
                  ? sortConfig.direction === 'ascending'
                    ? ' ↑'
                    : ' ↓'
                  : ''}
              </div>
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('brand')}
            >
              {t('brand')}
              {sortConfig.key === 'brand'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('description')}
            >
              {t('description')}
              {sortConfig.key === 'description'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('days')}
            >
              {t('days')}
              {sortConfig.key === 'days'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('price')}
            >
              {t('price_with_currency', { currency })}
              {sortConfig.key === 'price'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('pack')}
            >
              {t('pack')}
              {sortConfig.key === 'pack'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('available')}
            >
              {t('available')}
              {sortConfig.key === 'available'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            {isLoggedIn && (
              <TableCell
                className={classes.headerCell}
                onClick={() => requestSort('quantity')}
              >
                {t('quantity')}
                {sortConfig.key === 'quantity'
                  ? sortConfig.direction === 'ascending'
                    ? ' ↑'
                    : ' ↓'
                  : ''}
              </TableCell>
            )}
            {1 == 0 && (
              <TableCell
                onClick={() => requestSort('rating')}
                className={classes.ratingCell}
              >
                Rating
              </TableCell>
            )}
            <TableCell
              className={classNames(classes.weightCell, classes.headerCell)}
            >
              <div onClick={() => requestSort('weightKg')}>
                {t('weight_kg')}
                {sortConfig.key === 'weightKg'
                  ? sortConfig.direction === 'ascending'
                    ? ' ↑'
                    : ' ↓'
                  : ''}
              </div>
              <div onClick={() => requestSort('volumeKg')}>
                {t('volume_kg')}
                {sortConfig.key === 'volumeKg'
                  ? sortConfig.direction === 'ascending'
                    ? ' ↑'
                    : ' ↓'
                  : ''}
              </div>
            </TableCell>
            {showReference && <TableCell>{t('reference')}</TableCell>}
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('booking')}
            >
              {t('booking')}
              {sortConfig.key === 'booking'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
            <TableCell
              className={classes.headerCell}
              onClick={() => requestSort('comment')}
            >
              {t('comment')}
              {sortConfig.key === 'comment'
                ? sortConfig.direction === 'ascending'
                  ? ' ↑'
                  : ' ↓'
                : ''}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedItems.map((value) => (
            <TableRow key={value.id}>
              <TableCell padding="checkbox">
                <RowCheckbox
                  id={value.id}
                  className={classes.checkbox}
                  checked={value.confirmed}
                  onChange={onSelect}
                />
              </TableCell>
              <TableCell>
                <div>{value.partNumber}</div>
                <div className={classes.warnValue}>
                  {value.inpPartNumber !== value.partNumber
                    ? value.inpPartNumber
                    : ''}
                </div>
              </TableCell>
              <TableCell>{value.brand}</TableCell>
              <TableCell>{value.description}</TableCell>
              <TableCell>
                <div className={classes.pointValue}>{value.tariff}</div>
                <div>{value.days}</div>
              </TableCell>
              <TableCell>{value.price}</TableCell>
              <TableCell>{value.pack}</TableCell>
              <TableCell>
                {value.available
                  ? value.available
                  : value.price
                  ? 'on stock'
                  : 'N/A'}
              </TableCell>
              {isLoggedIn && (
                <TableCell>
                  <QuantityField
                    className={classes.quantityField}
                    id={value.id}
                    quantity={value.quantity}
                    onChange={handleQuantityChange}
                  />
                </TableCell>
              )}
              {showReference && <TableCell>{value.yourReference}</TableCell>}
              <TableCell>
                <div className={classes.weightValue}>{value.weightKg}</div>
                <div>{value.volumeKg}</div>
              </TableCell>
              <TableCell>{value.booking}</TableCell>
              <TableCell>
                <div className={classes.warnValue}>
                  {' '}
                  {t(
                    value.quantity % value.pack
                      ? 'Pack violation'
                      : value.errDesc
                  )}{' '}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
    container: {
      flex: '1 0 0px',
    },
    weightCell: {
      whiteSpace: 'nowrap',
    },
    weightValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    pointValue: {
      borderBottom: `1px solid ${colors.weightValueBorder}`,
    },
    ratingCell: {
      textAlign: 'left',
      padding: 20,
    },
    rating: {
      display: 'flex',
    },
    ratingIcon: {
      width: 20,
      height: 20,
    },
    ratingIconButton: {
      padding: 1,
    },
    quantityField: {
      alignItems: 'center',
    },
    warnValue: {
      color: 'red',
    },
    checkbox: {
      padding: 0,
    },
    headerCell: {
      cursor: 'pointer',
    },
    warnValue: {
      color: 'red',
    },
  };
};

export default withStyles(styles)(SearchTable);
